import { useState, useEffect } from "react";
import ClipboardJS from "clipboard";
import infoSvg from "../media/svg/info.svg";
import copySvg from "../media/svg/copy.svg";
import boostyPng from "../media/png/boosty.png";
import bitcoinPng from "../media/png/bitcoin.png";
import usdtPng from "../media/png/usdt.png";
import ethPng from "../media/png/eth.png";

function Donate() {
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        // Инициализация ClipboardJS
        const clipboard = new ClipboardJS(".donate__address");

        clipboard.on("success", () => {
            setIsCopied(true);
            // Скрыть сообщение через 3 секунды
            setTimeout(() => {
                setIsCopied(false);
            }, 1900);
        });

        // Очистка на размонтировании компонента
        return () => {
            clipboard.destroy();
        };
    }, [isCopied]);

    return (
        <>
            <h2
                className="donate__title"
                style={{ textTransform: "uppercase" }}
            >
                <span style={{ color: "#4258BE" }}>Поддержать</span> проект
            </h2>
            <div className="donate__pc">
                <div className="donate__wrapper">
                    <div className="donate__left">
                        <h3 className="donate__header">Boosty | Из России</h3>
                        <div
                            className="donate__rectangle"
                            style={{ width: "87%" }}
                        >
                            <div className="info__icon">
                                <img src={infoSvg} alt="Info"></img>
                            </div>
                            <p className="donate__info">
                                Кликните по кнопке ниже или отсканируйте QR-код.{" "}
                                <br></br>
                                Через бусти можно пожертвовать единоразово или{" "}
                                <br></br>
                                оформить подписку.
                            </p>
                        </div>
                        <a
                            href="https://boosty.to/tvoygolos"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                        >
                            <div className="donate__button">
                                <p className="button__link">
                                    Перейти на страницу в Boosty
                                </p>
                            </div>
                        </a>
                    </div>
                    <div className="donate__right">
                        <img
                            src={boostyPng}
                            alt="Qr"
                            className="qr__rectangle"
                            style={{ height: "340px" }}
                        />
                    </div>
                </div>

                <div className="donate__wrapper inner">
                    <div className="donate__left" style={{ marginTop: "-7px" }}>
                        <img
                            src={bitcoinPng}
                            alt="Qr"
                            className="qr__rectangle"
                            style={{ height: "340px" }}
                        />
                    </div>
                    <div className="donate__right donate__reverse">
                        <h3 className="donate__header">BTC | Bitcoin</h3>
                        <div
                            className="donate__rectangle"
                            style={{ width: "87%" }}
                        >
                            <div className="info__icon">
                                <img src={infoSvg} alt="Info"></img>
                            </div>
                            <p className="donate__info">
                                Отсканируйте QR-код или скопируйте номер
                                кошелька <br></br> для анонимного пожертвования
                            </p>
                        </div>
                        <div
                            className="donate__address"
                            data-clipboard-action="copy"
                            data-clipboard-text="bc1qgn7dwpln8qks580esmwtm0atkl288ema7n3zwg"
                        >
                            <p className="address__link">
                                bc1qgn7dwpln8qks580esmwtm0atkl288ema7n3zwg
                            </p>
                            <img src={copySvg} alt="Copy"></img>
                        </div>
                        {isCopied && (
                            <div
                                className={
                                    "donate__toast " + (isCopied ? "show" : "")
                                }
                            >
                                Адрес скопирован успешно!
                            </div>
                        )}
                    </div>
                </div>

                <div className="donate__wrapper inner">
                    <div className="donate__left">
                        <h3 className="donate__header">USDT | TRC20</h3>
                        <div
                            className="donate__rectangle"
                            style={{ width: "100%" }}
                        >
                            <div className="info__icon">
                                <img src={infoSvg} alt="Info"></img>
                            </div>
                            <p className="donate__info">
                                Отсканируйте QR-код или скопируйте номер{" "}
                                <br></br> кошелька для анонимного пожертвования
                            </p>
                        </div>

                        <div
                            className="donate__address"
                            data-clipboard-action="copy"
                            data-clipboard-text="TAooSpSmSkRdbFPzJaeaWG8vXCFA4uW7US"
                            style={{ width: "105.7%" }}
                        >
                            <p className="address__link">
                                TAooSpSmSkRdbFPzJaeaWG8vXCFA4uW7US
                            </p>
                            <img src={copySvg} alt="Copy"></img>
                        </div>
                    </div>
                    <div className="donate__right">
                        <img
                            src={usdtPng}
                            alt="Qr"
                            className="qr__rectangle"
                            style={{ height: "340px", marginTop: "-7px" }}
                        />
                    </div>
                </div>

                <div className="donate__wrapper inner">
                    <div className="donate__left" style={{ marginTop: "-7px" }}>
                        <img
                            src={ethPng}
                            alt="Qr"
                            className="qr__rectangle"
                            style={{ height: "340px" }}
                        />
                    </div>
                    <div className="donate__right donate__reverse">
                        <h3 className="donate__header">ETH | Ethereum</h3>
                        <div className="donate__rectangle">
                            <div className="info__icon">
                                <img src={infoSvg} alt="Info"></img>
                            </div>
                            <p className="donate__info">
                                Отсканируйте QR-код или скопируйте номер
                                кошелька <br></br> для анонимного пожертвования
                            </p>
                        </div>
                        <div
                            className="donate__address"
                            data-clipboard-action="copy"
                            data-clipboard-text="0xD96FBDfb37E15d138BD2BE5E107551B22A4E88C5"
                            style={{ width: "96%" }}
                        >
                            <p className="address__link">
                                0xD96FBDfb37E15d138BD2BE5E107551B22A4E88C5
                            </p>
                            <img src={copySvg} alt="Copy"></img>
                        </div>
                        {isCopied && (
                            <div
                                className={
                                    "donate__toast " + (isCopied ? "show" : "")
                                }
                            >
                                Адрес скопирован успешно!
                            </div>
                        )}
                    </div>
                </div>

                <div className="mail__wrapper">
                    <p className="mail__text">
                        Для совершения крупных пожертвований просьба связаться
                        через почту
                    </p>
                </div>
            </div>
            <div className="donate__mobile">
                <div className="donate__wrapper">
                    <h3 className="donate__header donate__header-mobile">
                        Boosty | Из России
                    </h3>
                    <div className="donate__rectangle rectangle__tablet">
                        <div className="info__icon">
                            <img src={infoSvg} alt="Info"></img>
                        </div>
                        <p className="donate__info">
                            Нажмите на кнопку для перехода на страницу Boosty
                        </p>
                    </div>
                    <a
                        href="https://boosty.to/tvoygolos"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                    >
                        <div className="donate__button">
                            <p className="button__link">
                                Перейти на страницу в Boosty
                            </p>
                        </div>
                    </a>
                    <h3
                        className="donate__header donate__header-mobile"
                        style={{
                            color: "#4258BE",
                        }}
                    >
                        Криптовалюта
                    </h3>
                    <div className="donate__rectangle rectangle__tablet">
                        <div className="info__icon">
                            <img src={infoSvg} alt="Info"></img>
                        </div>
                        <p className="donate__info">
                            Скопируйте номер кошелька для пожертвования
                        </p>
                    </div>
                    <h3 className="donate__header donate__header-mobile">
                        BTC | Bitcoin
                    </h3>
                    <div
                        className="donate__address"
                        data-clipboard-action="copy"
                        data-clipboard-text="bc1qgn7dwpln8qks580esmwtm0atkl288ema7n3zwg"
                    >
                        <p className="address__link address__link-tablet">
                            bc1qgn7dwpln8qks580esmwtm0atkl288ema7n3zwg
                        </p>
                        <p className="address__link address__link-small-tablet">
                            bc1qgn7dwpln8qks580esmwtm0at...
                        </p>
                        <p className="address__link address__link-mobile">
                            bc1qgn7dwpln8qks580es...
                        </p>

                        {isCopied && (
                            <div
                                className={
                                    "donate__toast " + (isCopied ? "show" : "")
                                }
                            >
                                Адрес скопирован успешно!
                            </div>
                        )}
                        <img
                            src={copySvg}
                            alt="Copy"
                            className="copy__svg"
                        ></img>
                    </div>
                    <h3 className="donate__header donate__header-mobile">
                        USDT | TRC20
                    </h3>
                    <div
                        className="donate__address"
                        data-clipboard-action="copy"
                        data-clipboard-text="TAooSpSmSkRdbFPzJaeaWG8vXCFA4uW7US"
                    >
                        <p className="address__link address__link-tablet">
                            TAooSpSmSkRdbFPzJaeaWG8vXCFA4uW7US
                        </p>
                        <p className="address__link address__link-small-tablet">
                            TAooSpSmSkRdbFPzJaeaWG8vXC...
                        </p>
                        <p className="address__link address__link-mobile">
                            TAooSpSmSkRdbFPzJaea...
                        </p>

                        <img
                            src={copySvg}
                            alt="Copy"
                            className="copy__svg"
                        ></img>
                    </div>
                    <h3 className="donate__header donate__header-mobile">
                        ETH | Ethereum
                    </h3>
                    <div
                        className="donate__address"
                        data-clipboard-action="copy"
                        data-clipboard-text="0xD96FBDfb37E15d138BD2BE5E107551B22A4E88C5"
                    >
                        <p className="address__link address__link-tablet">
                            0xD96FBDfb37E15d138BD2BE5E107551B22A4E88C5
                        </p>
                        <p className="address__link address__link-small-tablet">
                            0xD96FBDfb37E15d138BD2BE5E107...
                        </p>
                        <p className="address__link address__link-mobile">
                            0xD96FBDfb37E15d138BD...
                        </p>
                        <img
                            src={copySvg}
                            alt="Copy"
                            className="copy__svg"
                        ></img>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Donate;
