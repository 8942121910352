import React, { useEffect, useRef, useState } from "react";
import ClipboardJS from "clipboard";

function ShareButton() {
    const [isCopied, setIsCopied] = useState(false);
    const clipboardRef = useRef(null);

    useEffect(() => {
        // Инициализация ClipboardJS
        const clipboard = new ClipboardJS(clipboardRef.current);

        clipboard.on("success", () => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 1900);
        });

        clipboard.on("error", (e) => {
            console.error("Error copying to clipboard: ", e);
        });

        return () => {
            clipboard.destroy();
        };
    }, []);

    const handleShare = async () => {
        const shareData = {
            title: "Твой голос",
            url: "https://tvoygolos.me",
        };

        try {
            if (navigator && navigator.share) {
                await navigator.share(shareData);
                console.log("shared successfully");
            } else {
                if (clipboardRef.current) {
                    clipboardRef.current.click();
                }
            }
        } catch (e) {
            if (e.toString().includes("AbortError")) {
                console.log("error");
            } else {
                console.error("Error sharing content:", e);
            }
        }

        // if (navigator.share) {
        //     try {
        //         await navigator.share(shareData);
        //         console.log("Content shared successfully");
        //     } catch (err) {
        //         console.error("Error sharing content: ", err);
        //     }
        // } else {
        //     // Использовать ClipboardJS
        //     if (clipboardRef.current) {
        //         clipboardRef.current.click();
        //     }
        // }
    };

    return (
        <div className="footer__share" onClick={handleShare}>
            <span>Поделиться ссылкой на сайт</span>
            <button
                ref={clipboardRef}
                data-clipboard-text="https://tvoygolos.me"
                style={{ display: "none" }}
            ></button>
            {isCopied && (
                <div className={"donate__toast " + (isCopied ? "show" : "")}>
                    Адрес скопирован успешно!
                </div>
            )}
        </div>
    );
}

export default ShareButton;
