import "./App.css";
import tiktokSvg from "./media/svg/tiktok.svg";
import tiktokMobileSvg from "./media/svg/tiktokmobile.svg";

import xSvg from "./media/svg/x.svg";
import xMobileSvg from "./media/svg/xmobile.svg";

import telegramSvg from "./media/svg/telegram.svg";
import telegramMobileSvg from "./media/svg/telegrammobile.svg";
import botSvg from "./media/svg/bot.svg";

import Select from "./components/Select.jsx";
import Accordion from "./components/Accordion.jsx";
import Donate from "./components/Donate.jsx";
import Methods from "./components/Methods.jsx";
import ShareButton from "./components/ShareButton.jsx";

function App() {
    return (
        <>
            <div className="content">
                <div id="search" className="box__wrapper">
                    <header>
                        <div className="header__pc">
                            <div className="header__rectangle">
                                <span style={{ textTransform: "uppercase" }}>
                                    Твой голос
                                </span>
                            </div>
                            <div className="header__nav">
                                <nav>
                                    <a href="#search" className="nav__link">
                                        Поиск
                                    </a>
                                    <a href="#methods" className="nav__link">
                                        Способы
                                    </a>
                                    <a href="#faq" className="nav__link">
                                        Вопросы
                                    </a>
                                    <a href="#donate" className="nav__link">
                                        Поддержать
                                    </a>
                                </nav>
                            </div>
                            <a
                                href="https://t.me/project_votebot"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="tg__link"
                            >
                                <div className="header__rectangle">
                                    Телеграм-бот
                                </div>
                            </a>
                        </div>
                        <div className="header__mobile">
                            <div className="mobile__rectangle">
                                <span style={{ textTransform: "uppercase" }}>
                                    Твой голос
                                </span>
                            </div>
                            <a
                                className="social__link"
                                href="https://www.tiktok.com/@project_vote"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={tiktokMobileSvg} alt="Tiktok"></img>
                            </a>
                            <a
                                className="social__link"
                                href="https://x.com/project_golos"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={xMobileSvg} alt="X(Twitter)"></img>
                            </a>
                            <a
                                className="social__link"
                                href="https://t.me/project_vote"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={telegramMobileSvg}
                                    alt="Telegram"
                                ></img>
                            </a>
                            <a
                                className="social__link"
                                href="https://t.me/project_votebot"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={botSvg} alt="Bot"></img>
                            </a>
                        </div>
                    </header>
                    <div className="text__container">
                        <div className="vote__container">
                            <h1
                                className="vote__text"
                                style={{ textTransform: "uppercase" }}
                            >
                                Голос за <br></br> изменения <br></br>
                                начинается здесь!
                            </h1>
                        </div>
                        <div className="social__container">
                            <p className="social__text">
                                Подпишитесь <br></br>
                                <span style={{ color: "rgba(0, 0, 0, 0.5" }}>
                                    на наши <br></br>
                                    соцсети, <br></br>
                                    чтобы не <br></br>
                                    потерять <br></br>
                                    нас:
                                </span>
                            </p>
                            <a
                                className="social__link"
                                href="https://www.tiktok.com/@project_vote"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={tiktokSvg} alt="Tiktok"></img>
                            </a>
                            <a
                                className="social__link"
                                href="https://x.com/project_golos"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={xSvg} alt="X(Twitter)"></img>
                            </a>
                            <a
                                className="social__link"
                                href="https://t.me/project_vote"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={telegramSvg} alt="Telegram"></img>
                            </a>
                        </div>
                    </div>
                    <div className="search_wrapper">
                        <p className="search__text">
                            Для поиска кандидата начните вводить свой адрес по
                            паспорту. {""}
                            <span style={{ color: "#4258BE" }}>
                                Это анонимно.
                            </span>
                        </p>

                        <Select />
                    </div>
                </div>
                <div id="methods" className="title__box">
                    <h2
                        className="methods__header"
                        style={{ textTransform: "uppercase" }}
                    >
                        <span style={{ color: "#4258BE" }}>Способы</span>{" "}
                        протестного голосования
                    </h2>
                </div>
                <Methods />
                <div id="faq" className="title__box">
                    <h2
                        className="methods__header"
                        style={{ textTransform: "uppercase" }}
                    >
                        Ваши <span style={{ color: "#4258BE" }}>вопросы</span> и
                        ответы на них
                    </h2>
                </div>
                <div className="accordion">
                    <Accordion />
                </div>
                <div id="donate" className="donate">
                    <Donate />
                </div>
                <footer>
                    <div className="footer__title">
                        <h2 style={{ textTransform: "uppercase" }}>
                            Чем больше людей узнает
                            {/* <br></br>  */} о сервисе, тем больше {""}
                            {/* <br></br>  */}
                            шансов на успех.
                        </h2>
                    </div>
                    <div className="footer__text">
                        <p>
                            Расскажите своим близким и знакомым и покажите{" "}
                            <br></br> рекомендуемых кандидатов
                        </p>
                    </div>
                    <div className="footer__links">
                        {/* <div className="footer__share"> */}
                        <ShareButton />
                        {/* </div> */}

                        <div className="footer__link">
                            <a
                                href="https://t.me/project_votebot"
                                style={{
                                    textDecoration: "none",
                                    color: "#ffffff",
                                }}
                            >
                                Телеграм-бот
                            </a>
                        </div>
                        <div className="social__footer">
                            <a
                                href="https://www.tiktok.com/@project_vote"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={tiktokSvg}
                                    alt="Tiktok"
                                    className="footer__img"
                                ></img>
                            </a>
                        </div>
                        <div className="social__footer">
                            <a
                                href="https://x.com/project_golos"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={xSvg}
                                    alt="X(Twitter)"
                                    className="footer__img"
                                ></img>
                            </a>
                        </div>
                        <div className="social__footer">
                            <a
                                href="https://t.me/project_vote"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={telegramSvg}
                                    alt="Telegram"
                                    className="footer__img"
                                ></img>
                            </a>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default App;
