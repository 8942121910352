import { useState } from "react";
import { useRef } from "react";
import Plus from "../media/svg/plus.svg";
import Minus from "../media/svg/minus.svg";

const MinusIcon = () => (
    <img className="minus__icon" src={Minus} alt="minus"></img>
);
const PlusIcon = () => <img className="plus__icon" src={Plus} alt="plus"></img>;

const AccordionItem = ({ faq }) => {
    const [clicked, setClicked] = useState(false);
    const contentEl = useRef();

    const { question, answer } = faq;

    const handleToggle = () => {
        setClicked((prev) => !prev);
    };

    return (
        <li className={`accordion_item ${clicked ? "active" : ""}`}>
            <button className="button" onClick={handleToggle}>
                <p className="question"> {question}</p>
                <span className="control">
                    {clicked ? <MinusIcon /> : <PlusIcon />}{" "}
                </span>
            </button>

            <div
                ref={contentEl}
                className={
                    "answer__wrapper " + (clicked ? "fadeIn" : "fadeOut")
                }
                style={{
                    height: clicked
                        ? contentEl.current &&
                          contentEl.current
                              .querySelector(".answer")
                              .clientHeight.toString() + "px"
                        : "",
                }}
            >
                <div className="answer">{answer}</div>
            </div>
        </li>
    );
};

export default AccordionItem;
