import { faqs } from "../data";
import AccordionItem from "./AccordionItem.jsx";

function Accordion() {
    return (
        <>
            <ul className="accordion">
                {faqs.map((faq, index) => (
                    <AccordionItem key={index} faq={faq} />
                ))}
            </ul>
        </>
    );
}

export default Accordion;
