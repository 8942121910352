export const faqs = [
    {
        question:
            "Почему вы предлагаете голосовать за кандидатов, которые не придерживаются демократических взглядов?",
        answer: "Независимых кандидатов не допустили, но это не значит, что не нужно ходить на выборы. Ваш голос за предложенного кандидата поможет победить Единую Россию на выборах. Конечно мы не можем обещать, что выбранные нашим сервисом кандидаты, сделают нашу жизнь лучше, но когда парламент становится местом обсуждений, а не местом кнопкодавства, то жизнь действительно улучшается.",
    },
    {
        question: "Почему нужно приходить на выборы 8 сентября?",
        answer: "В течение трех дней голосования риск вбросов в ночь с шестого на седьмое и с седьмого на восьмое число крайне высок. Не исключено, что после закрытия избирательного участка на ночь, голоса могут быть фальсифицированы, как это было на президентских выборах. Приходя на участок 8 сентября, вы сохраняете свой голос и минимизируете возможность фальсификации при подсчете.",
    },
    {
        question:
            "Как вы отбираете кандидатов, за которых призываете голосовать?",
        answer: "В каждом округе мы выбирает наиболее конкурентоспособного кандидата: изучаем его электоральный опыт, оцениваем узнаваемость, просматриваем последние социологические опросы. И только после этого мы делаем выбор в пользу кандидата.",
    },
    {
        question:
            "Зачем фотографировать свой бюллетень с отметкой за кандидата и отправлять в ваш бот? ",
        answer: "Фотографии, которые вы отправите в наш бот, будут обезличены, подсчитаны и представлены в виде статистических данных. После этого мы сверимся с итогами выборов, и если они прошли не честно, то это будет сразу заметно. После этого кандидаты, которые де-факто выиграли, смогут обратиться в суд, чтобы аннулировать итоги голосования по конкретному участку.",
    },
    {
        question:
            "Вас заставляют голосовать 6 или 7 сентября,  или участвовать в ДЭГ?",
        answer: (
            <>
                Участие в выборах является полностью добровольным и принуждать
                вас никто не может, даже работодатель. Если вас все-таки
                принуждают или вы вам стало известно о нарушениях на выборах, то
                сообщите нам на почту{" "}
                <a
                    href="mailto:golos.project@proton.me"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#4258BE", textDecoration: "none" }}
                >
                    golos.project@proton.me
                </a>{" "}
                или в Телеграм{" "}
                <a
                    href="https://t.me/tulavote_help"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#4258BE", textDecoration: "none" }}
                >
                    @tulavote_help
                </a>
                .
            </>
        ),
    },
    {
        question: "Где можно будет ознакомиться с итогами этой кампании?",
        answer: (
            <>
                В нашем{" "}
                <a
                    href="https://t.me/project_vote"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#4258BE", textDecoration: "none" }}
                >
                    Телеграм-канале
                </a>{" "}
                мы публикуем последние новости и всю аналитику, которую собираем
                в этой кампании. Советуем подписаться, чтобы быть в курсе всех
                новостей.
            </>
        ),
    },
    {
        question: "Можно ли вас финансово поддержать?",
        answer: (
            <>
                Да, можно оформить подписку или сделать единоразовый перевод
                через{" "}
                <a
                    href="https://boosty.to/tvoygolos"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#4258BE", textDecoration: "none" }}
                >
                    Boosty
                </a>{" "}
                из России, а также задонатить через криптовалюту: Bitcoin, USDT
                (TRC20) и Ethereum.
            </>
        ),
    },
    {
        question: "Как с вами связаться?",
        answer: (
            <>
                Это можно сделать через наш{" "}
                <a
                    href="https://t.me/project_votebot"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#4258BE", textDecoration: "none" }}
                >
                    Телеграм-бот
                </a>{" "}
                , выбрав в меню бота “Связаться с редакцией” или через нашу
                почту{" "}
                <a
                    href="mailto:golos.project@proton.me"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#4258BE", textDecoration: "none" }}
                >
                    golos.project@proton.me
                </a>{" "}
            </>
        ),
    },
];
