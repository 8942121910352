import React, { useState, useCallback, useMemo } from "react";
import { AutoComplete } from "antd";
import { debounce } from "lodash";
import { VoteClient } from "../api/voteClient";

const debouncedSearch = debounce(
    async (value, voteClient, searchResult, setOptions) => {
        if (value) {
            const stations = await voteClient.searchPollingStations(value);
            if (stations != null) {
                setOptions(searchResult(stations));
            }
        } else {
            setOptions([]);
        }
    },
    300
);

function Select() {
    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(""); // Для отображения ошибки
    const [selectedCommittee, setSelectedCommittee] = useState([]);
    const [filteredCandidates, setFilteredCandidates] = useState([]); // Отфильтрованные кандидаты
    const [isFiltered, setIsFiltered] = useState(false);

    // const voteClient = new VoteClient();
    const voteClient = useMemo(() => new VoteClient(), []);

    const onSelect = async (value, option) => {
        if (option.leaf) {
            setFilteredCandidates([]);
            setSelectedCommittee([]);
            setError("");

            setOpen(false);
            const committee = await voteClient.getCommittee(option.id);
            if (!committee || committee === 0) {
                setError("Данные избирательного участка не найдены.");
                return;
            }
            const votes = await voteClient.getVotes(
                committee.vrn,
                committee.subjCode,
                committee.num
            );
            if (!votes || votes.length === 0) {
                setError("Данные голосования не найдены.");
                return;
            }
            const vrns = votes.map((vote) => vote.vrn);
            const fetchedCandidates = await voteClient.getCandidates();
            const candidates = [...fetchedCandidates];
            const districts = await voteClient.getDistricts(
                committee.vrn,
                votes[0].vrn
            );
            let districtNum = -1;
            if (districts._embedded.tvdDtoList.length === 1) {
                districtNum = 0;
            } else {
                // Если элементов несколько, продолжаем искать selected
                districts._embedded.tvdDtoList.forEach(function (value, i) {
                    if (value.hasOwnProperty("selected")) {
                        districtNum = i;
                        console.log("districtNum", districtNum);
                    }
                });
            }

            // Если после всех проверок districtNum всё ещё -1, выводим ошибку
            if (districtNum === -1) {
                setError("Не найден номер округа.");
                return;
            }
            // Фильтруем кандидатов
            const filtered = candidates.filter((candidate) => {
                // Преобразуем region в число, если он пустой, то тоже преобразуем в число (0)
                const region = Number(candidate.region) || 0;
                return (
                    (region === districtNum || !candidate.region) &&
                    vrns.includes(candidate.id.toString())
                );
            });
            setSelectedCommittee([
                {
                    name: committee.name,
                    address: committee.address.address,
                },
            ]);
            setFilteredCandidates(filtered);
            setIsFiltered(true);
            setError(""); // Сбрасываем ошибку если все прошло успешно
        } else {
            setTimeout(async () => {
                setOpen(true);
            }, 350);
        }
    };

    const searchResult = useCallback((pollingStations) => {
        return pollingStations.map((options) => {
            return {
                value: options.name,
                id: options.id,
                leaf: options.leaf,
                label: (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{options.name}</span>
                    </div>
                ),
            };
        });
    }, []);

    const handleSearch = useCallback(
        (value) => {
            debouncedSearch(value, voteClient, searchResult, setOptions);
        },
        [voteClient, searchResult]
    );

    return (
        <>
            <AutoComplete
                className="custom__autocomplete"
                popupMatchSelectWidth="100%"
                variant="borderless"
                options={options}
                onSelect={onSelect}
                onSearch={handleSearch}
                dropdownRender={(menu) => (
                    <div className="custom__dropdown">{menu}</div>
                )}
                open={open}
                // open={true}
                placeholder="Начните вводить адрес..."
                onDropdownVisibleChange={(isOpen) => setOpen(isOpen)}
            ></AutoComplete>

            <div className={`content__wrapper ${isFiltered ? "show" : ""}`}>
                {isFiltered && filteredCandidates.length > 0
                    ? filteredCandidates.map((v, ind) => (
                          <div className="candidate__place" key={ind}>
                              <div className="candidate__rectangle">
                                  <span className="candidate__rectangle-text">
                                      {v.election}
                                  </span>
                              </div>
                              <div className="candidate__place-content">
                                  <p className="candidate__place-text">
                                      {v.name}
                                  </p>
                                  <p className="candidate__place-subtext">
                                      {v.party}
                                  </p>
                              </div>
                          </div>
                      ))
                    : isFiltered &&
                      filteredCandidates.length === 0 && (
                          <div className="candidate__place">
                              <div className="candidate__rectangle">
                                  <span className="candidate__rectangle-text">
                                      Ошибка поиска
                                  </span>
                              </div>
                              <div className="candidate__place-content">
                                  <p className="candidate__place-errortext">
                                      К сожалению, регион поиска не участвует в
                                      нашем проекте или по вашему адресу не
                                      проходят выборы
                                  </p>
                              </div>
                          </div>
                      )}

                {error && (
                    <div className="candidate__place">
                        <div className="candidate__rectangle">
                            <span className="candidate__rectangle-text">
                                Ошибка поиска
                            </span>
                        </div>
                        <div className="candidate__place-content">
                            <p className="candidate__place-errortext">
                                {error}
                            </p>
                        </div>
                    </div>
                )}
            </div>
            {isFiltered &&
                filteredCandidates.length > 0 &&
                selectedCommittee.map((v, ind) => (
                    <div className="vote__wrapper" key={ind}>
                        <p className="vote__place-text">
                            {v.name && v.name.trim() !== ""
                                ? v.name
                                : "Ошибка получения номера УИК"}
                        </p>
                        <p className="vote__place-text">
                            Адрес голосования:
                            {v.address && v.address.trim() !== ""
                                ? v.address.replace(/^\d+,/, "")
                                : " ошибка получения адреса"}
                        </p>
                    </div>
                ))}
        </>
    );
}

export default Select;
