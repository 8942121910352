function Methods() {
    return (
        <div className="methods__wrapper">
            <div className="methods__box methods__box--first">
                1. Проголосуйте за тех кандидатов, которых мы порекомендовали в
                своём сервисе. Для этого просто введите адрес прописки по
                паспорту и получите список кандидатов, за которых лучше всего
                проголосовать
            </div>
            <div className="methods__box methods__smallbox methods__box--second ">
                2. Не голосуйте дистанционно. Голос, который вы отдали за
                кандидата через ДЭГ, будет украден.
            </div>
            <div className="methods__box methods__smallbox methods__box--first">
                3. Сфотографируйте бюллетень и отправьте в наш бот. Имея
                фотографии, можно будет доказать, что выборы прошли не честно.
            </div>
            <div className="methods__box methods__box--second box__margin">
                4. Приходите голосовать в последний день выборов, 8 сентября.
                Голос, который отдан 6 или 7 сентября, с большим шансом будет
                украден.
            </div>
        </div>
    );
}

export default Methods;
