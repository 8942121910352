export class VoteClient {
    constructor() {
        this.host = "https://tvoygolos.me";

        // this.host = "http://localhost:8080";
    }

    async searchPollingStations(value) {
        return await fetch(`${this.host}/api/search?q=${value}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => data)
            .catch((error) => {
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            });
    }

    async getCommittee(id) {
        return fetch(`${this.host}/api/committee?id=${id}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => data)
            .catch((error) => {
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            });
    }

    async getVotes(committeeId, subjCode, num) {
        return fetch(
            `${this.host}/api/votes?committeeId=${committeeId}&subjCode=${subjCode}&num=${num}`
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => data)
            .catch((error) => {
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            });
    }

    async getReport(vrn, committeeId) {
        return fetch(
            `${this.host}/api/report?vrn=${vrn}&committeeId=${committeeId}`
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => data)
            .catch((error) => {
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            });
    }

    async getCandidates() {
        return fetch(`${this.host}/api/candidates`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => data)
            .catch((error) => {
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            });
    }

    async getDistricts(committeeId, vrn) {
        return fetch(
            `${this.host}/api/district?vrn=${vrn}&committeeId=${committeeId}`
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => data)
            .catch((error) => {
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            });
    }
}
